import { Carousel, CarouselProps } from 'xt-design'
import { useEffect, useRef } from 'react'
import classnames from 'classnames'
import styles from './index.module.scss'
import CusIcon from 'components/CusIcon'
import SlickButtonFix from '../SlickButtonFix';

interface CustomCarouselProps extends CarouselProps {
  length: number
  arrowPosition?: 'left' | 'center' | 'right'
  className?: string
}

const CustomCarousel = (props: CustomCarouselProps) => {
  const {
    children,
    length,
    arrowPosition = 'center',
    className,
    autoplay = true,
    ...otherProps
  } = props

  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const dotsHalfWidth = (40 * length - 8) / 2 + 80
    const section = ref.current
    const _prevArrow = section?.querySelector('.slick-arrow.slick-prev') as HTMLElement
    const _nextArrow = section?.querySelector('.slick-arrow.slick-next') as HTMLElement
    switch (arrowPosition) {
      case 'left':
        _prevArrow.style.left = '0'
        _nextArrow.style.right = `calc(100% - ${dotsHalfWidth * 2}px)`
        break
      case 'right':
        _prevArrow.style.left = `calc(100% - ${dotsHalfWidth * 2}px)`
        _nextArrow.style.right = '0'
        break
      default:
        _prevArrow.style.left = `calc(50% - ${dotsHalfWidth}px)`
        _nextArrow.style.right = `calc(50% - ${dotsHalfWidth}px)`
        break
    }
  }, [arrowPosition, length])

  return (
    <div
      className={classnames(
        styles.wrapper,
        {
          [styles.leftArrow]: arrowPosition === 'left',
          [styles.rightArrow]: arrowPosition === 'right',
        },
        className,
      )}
      ref={ref}
    >
      <Carousel
        prevArrow={<SlickButtonFix><CusIcon type="xthoutui" /></SlickButtonFix>}
        nextArrow={<SlickButtonFix><CusIcon type="xtqianjin" /></SlickButtonFix>}
        autoplay={autoplay} {...otherProps}
      >
        {children}
      </Carousel>
    </div>
  )
}

export default CustomCarousel
