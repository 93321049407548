import React, { Fragment } from "react";
import { useTranslation } from "hooks";
import classnames from "classnames";
import { ContactUS, LeftAndRightRow, TitleBanner, TopBanner, ContactUsForm } from "components";
import { Account, BenefitsAniImg, Customer, FinancialServices, Media, Security, StreamlinedAniImg, ZeroAniImg } from "components/OldHomeComponents";
import styles from "./index.module.scss";
import { NextPageWithLayout } from "types/reactExtra";
import { useUserAgentState, useCommonState } from "context";

/**≠
 * 国际站
 */
const InternationalOfficial: NextPageWithLayout<PageProps> = (props) => {
  const { t } = useTranslation();
  const { isMobile } = useUserAgentState();
  const { isHKLandingConsult } = useCommonState();
  const imgs = {
    usd: t("home-topBanner-img-usd"),
    eur: t("home-topBanner-img-eur"),
    gbp: t("home-topBanner-img-gbp"),
    hkd: t("home-topBanner-img-hkd"),
    curve: t("home-topBanner-img-curve"),
    curveBg: t("home-topBanner-img-curveBg"),
    security: t("home-topBanner-img-security"),
    account: t("home-topBanner-img-account"),
  } as unknown as {
    usd: string;
    eur: string;
    gbp: string;
    hkd: string;
    curve: string;
    curveBg: string;
    security: string;
    account: string;
  };

  return (
    <Fragment>
      {!isHKLandingConsult && (
        <TopBanner
          title={props.isMobile || isMobile ? t("home-topBanner-mobile-title") : t("home-topBanner-title")}
          id={"homeTopBanner"}
          cusImg={
            <>
              <div className={classnames(styles.shadow, styles.curveBgWrapper)}>
                <img src={imgs.curveBg} alt={"curveBg"} className={"fill-image"} />
              </div>
              <div className={classnames(styles.shadow, styles.curveWrapper)}>
                <img src={imgs.curve} alt={"curve"} className={"fill-image"} />
              </div>
              <div className={classnames(styles.shadow, styles.usdWrapper)}>
                <img src={imgs.usd} alt={"usd"} className={"fill-image"} />
              </div>
              <div className={classnames(styles.shadow, styles.eurWrapper)}>
                <img src={imgs.eur} alt={"eur"} className={"fill-image"} />
              </div>
              <div className={classnames(styles.shadow, styles.gbpWrapper)}>
                <img src={imgs.gbp} alt={"gbp"} className={"fill-image"} />
              </div>
              <div className={classnames(styles.shadow, styles.hkdWrapper)}>
                <img src={imgs.hkd} alt={"hkd"} className={"fill-image"} />
              </div>
              <div className={classnames(styles.shadow, styles.securityWrapper)}>
                <img src={imgs.security} alt={"security"} className={"fill-image"} />
              </div>
              <div className={classnames(styles.shadow, styles.accountWrapper)}>
                <img src={imgs.account} alt={"account"} className={"fill-image"} />
              </div>
            </>
          }
          desc={t("home-topBanner-ul") as unknown as string[]}
        />
      )}
      {isHKLandingConsult && <ContactUsForm />}
      <TitleBanner className={classnames([styles.TitleBanner, isHKLandingConsult ? styles.landing2 : null])}>
        <FinancialServices />
        <LeftAndRightRow
          title={t("home-benefits-title")}
          id={"benefits"}
          left={{ title: t("home-benefits-subTitle"), subTitle: t("home-benefits-desc") }}
        >
          <BenefitsAniImg />
        </LeftAndRightRow>
        <LeftAndRightRow id={"account"} left={{ title: t("home-account-subTitle"), subTitle: t("home-account-desc") }}>
          <Account />
        </LeftAndRightRow>
        <LeftAndRightRow id={"zero"} left={{ title: t("home-zero-subTitle"), subTitle: t("home-zero-desc") }}>
          <ZeroAniImg />
        </LeftAndRightRow>
        <LeftAndRightRow id={"streamlined"} left={{ title: t("home-streamlined-subTitle"), subTitle: t("home-streamlined-desc") }}>
          <StreamlinedAniImg />
        </LeftAndRightRow>
      </TitleBanner>
      <TitleBanner>
        <Security ns={"home"} nsPrefix={"security"} />
        <Customer ns={"home"} nsPrefix={"customer"} />
        <Media />
      </TitleBanner>
      <ContactUS title={t("home-contact-us-banner-title")} />
    </Fragment>
  );
};

export default InternationalOfficial;
