import { cloneElement, Fragment, isValidElement, ReactElement } from 'react';

interface SlickButtonFixProps {
  children?: ReactElement,
  currentSlide?: number,
  slideCount?: number
}

const SlickButtonFix = ({ currentSlide, slideCount, children, ...props }: SlickButtonFixProps) => {

  if (isValidElement(children))
    return cloneElement(children, { ...props })
  return <Fragment>{children}</Fragment>
};

export default SlickButtonFix
