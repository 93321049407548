import React from "react";
import { useTranslation } from "hooks";
import styles from "./index.module.scss";
import { safeParse } from "utils";
import Title from "components/Title";
import { useUserAgentState } from "context";
import { IconCard } from "components";

interface DataItem {
  title: string;
  unit?: string;
  subTitle: string;
}

const FinancialServices = () => {
  const { t } = useTranslation();
  const { isMobile } = useUserAgentState();

  const list = (t(`home-description-ul`) || []) as DataItem[];

  return (
    <section className={styles.root}>
      <div className={"xt-row"}>
        {!isMobile ? (
          <Title className={`xt-h2 ${styles.title}`}>{t(`home-description-title-ul`)}</Title>
        ) : (
          <Title className={`xt-h2 ${styles.mobileTitle}`} onlyOneRow>
            {t(`home-description-title-ul`)}
          </Title>
        )}
        <ul className={styles.content}>
          {list?.map?.((i) => (
            <IconCard key={i.title} className={styles.item} {...i} icon={""} />
          ))}
        </ul>
      </div>
      <img
        src={"https://cdn-global.xtransfer.com/benefits-bg_dd02513582f7a295.svg"}
        alt={"benefits-bg"}
        className={styles.benefitsGg}
        loading={"lazy"}
      />
    </section>
  );
};

export default FinancialServices;
