import React from 'react'
import { useTranslation } from 'hooks'
import styles from './index.module.scss'
import AniImg from 'components/AniImg'
import classNames from 'classnames';
import { ComponentCommonProps } from 'types/reactExtra';
import AspectRatioImg from 'components/AspectRatioImg';
import { useUserAgentState } from 'context';

const ZeroAniImg = ({ className }: ComponentCommonProps) => {
  const { t } = useTranslation()
  const { isMobile } = useUserAgentState()

  return <AniImg className={classNames(className, styles.zero)}>
    {!isMobile ? <AspectRatioImg src={t('home-zero-img-account')} alt={'zero-Create currency account'} className={styles.img1} />
      : <AspectRatioImg src={t('home-zero-img-account-mobile')} alt={'zero-Create currency account'} className={styles.img1} />}
    <AspectRatioImg src={t('home-zero-img-fee')} alt={'zero-fee'} className={styles.img2} />
  </AniImg>
}

export default ZeroAniImg
