import React from 'react'
import { useTranslation } from 'hooks'
import styles from './index.module.scss'
import AniImg from 'components/AniImg'
import classNames from 'classnames';
import { ComponentCommonProps } from 'types/reactExtra';
import AspectRatioImg from 'components/AspectRatioImg';

const StreamlinedAniImg = ({ className }: ComponentCommonProps) => {
  const { t } = useTranslation()
    return <AniImg className={classNames(className, styles.streamlined)}>
      <AspectRatioImg src={t('home-streamlined-img-streamlined')!} alt={'streamlined'} className={styles.img1} />
    </AniImg>
}

export default StreamlinedAniImg
