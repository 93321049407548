import React, { CSSProperties } from 'react'
import classNames from 'classnames';
import styles from './index.module.scss'

interface AspectRatioImgProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  imgClassName?: string
  imgStyle?: CSSProperties
}

/**
 * 自适应图片容器
 * 必须设置paddingBottom属性
 */
const AspectRatioImg = (props: AspectRatioImgProps) => {
  const { imgClassName, imgStyle, alt, className, style, loading = 'lazy', ...rest } = props
  return <div className={classNames(styles.aspectRatioImg, className)} style={style}>
    <img alt={alt} className={classNames(styles['inner-img'], imgClassName)} style={imgStyle} loading={loading} {...rest} />
  </div>
}
export default AspectRatioImg
