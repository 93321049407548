import React from 'react'
import { useTranslation } from 'hooks'
import styles from './index.module.scss'
import AniImg from 'components/AniImg'
import classNames from 'classnames';
import { ComponentCommonProps } from 'types/reactExtra';
import AspectRatioImg from 'components/AspectRatioImg';

const Account = ({ className }: ComponentCommonProps) => {
  const { t } = useTranslation()

    return (
      <AniImg className={classNames(className, styles.account)}>
        <div className={styles.center}>
          <AspectRatioImg src={t('home-account-img-img6')} alt={'Payment received'} className={styles.img1} />
          <AspectRatioImg src={t('home-account-img-img5')} alt={'xt'} className={styles.img2} />
        </div>
        <div className={styles.left}>
          <AspectRatioImg src={t('home-account-img-img1')} alt={'us'} className={styles.img1} />
          <AspectRatioImg src={t('home-account-img-img2')} alt={'as'} className={styles.img2} />
          <AspectRatioImg src={t('home-account-img-img3')} alt={'switzerland'} className={styles.img3} />
          <AspectRatioImg src={t('home-account-img-img4')} alt={'Add more currencies'} className={styles.img4} />
        </div>
        <AspectRatioImg src={t('home-account-img-img7')} alt={'Credited'} className={styles.right} />
      </AniImg>)
}

export default Account
