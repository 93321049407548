
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/[site]",
      function () {
        return require("private-next-pages/[site]/index.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/[site]"])
      });
    }
  