import React from 'react'
import classNames from 'classnames'
import styles from './index.module.scss'
import { BannerProps } from 'types/international'
import Title from 'components/Title'
import { IconSec } from 'components'
import CusIcon from 'components/CusIcon'
import { useTranslation } from 'hooks'

const Banner = ({ ns, nsPrefix }: Pick<BannerProps, 'ns' | 'nsPrefix'>) => {
  const { t } = useTranslation()
  const ul = [
    t(`${ns}-${nsPrefix}-ul-fund`),
    t(`${ns}-${nsPrefix}-ul-transaction`),
    t(`${ns}-${nsPrefix}-ul-data`),
    t(`${ns}-${nsPrefix}-ul-platform`),
  ] as unknown as { title: string; desc: string }[]

  return (
    <section className={classNames('xt-row', styles.section)}>
      <Title className={styles.title}>{t(`${ns}-${nsPrefix}-title`)}</Title>
      <div className={styles.contentWrapper}>
        <IconSec
          icon={<CusIcon type="xtzijinanquan" className={styles.iconfont} />}
          title={ul[0]?.title}
          desc={ul[0]?.desc}
          className={styles.iconSec}
        />
        <IconSec
          icon={<CusIcon type="xtjiaoyianquan" className={styles.iconfont} />}
          title={ul[1]?.title}
          className={styles.iconSec}
          desc={ul[1]?.desc}
        />
        <IconSec
          icon={<CusIcon type="xtyinsibaohu" className={styles.iconfont} />}
          title={ul[2]?.title}
          desc={ul[2]?.desc}
          className={styles.iconSec}
        />
        <IconSec
          icon={<CusIcon type="xtxitonganquan" className={styles.iconfont} />}
          title={ul[3]?.title}
          desc={ul[3]?.desc}
          className={styles.iconSec}
        />
      </div>
    </section>
  )
}

export default Banner
