import React from 'react'
import { useTranslation } from 'hooks'
import styles from './index.module.scss'
import AniImg from 'components/AniImg'
import { ComponentCommonProps } from 'types/reactExtra';
import classNames from 'classnames';
import AspectRatioImg from 'components/AspectRatioImg';

const BenefitsAniImg = ({ className }: ComponentCommonProps) => {
  const { t } = useTranslation()
  return <AniImg className={classNames(className, styles.benefits)}>
    <AspectRatioImg src={t('home-benefits-img-women')} alt={'benefits-women'} className={styles.left} />
    <AspectRatioImg src={t('home-benefits-img-man')} alt={'benefits-man'} className={styles.right} />
  </AniImg>
}

export default BenefitsAniImg
