import React from 'react'
import styles from './index.module.scss'
import { BannerProps } from 'types/international'
import Title from 'components/Title'
import CustomerCard from 'components/CustomerCard'
import CustomCarousel from 'components/CustomCarousel'
import { useTranslation } from 'hooks'

const Customer = ({ ns, nsPrefix }: Pick<BannerProps, 'ns' | 'nsPrefix'>) => {
  const { t } = useTranslation()
  const ul = [
    t(`${ns}-${nsPrefix}-ul-1`),
    t(`${ns}-${nsPrefix}-ul-2`),
    t(`${ns}-${nsPrefix}-ul-3`),
  ] as unknown as {desc: string; author: string}[]

  return (
    <section className={styles.section}>
      <Title>{t(`${ns}-${nsPrefix}-title`)}</Title>
      <CustomCarousel arrows length={ul?.length ?? 0}>
        {ul.map((info, index) => (
          <CustomerCard key={index} info={{ ...info, img: t(`home-customer-img-customer${index + 1}`) as string }} />
        ))}
      </CustomCarousel>
    </section>
  )
}

export default Customer
