import styles from './index.module.scss'
import CusIcon from 'components/CusIcon'
import AniImg from 'components/AniImg'
import classNames from 'classnames';

const CustomerCard = ({ info }: { info: { img: string; desc: string; author: string } }) => {
  const { img, desc, author } = info;

  return (
    <div className={classNames('xt-row', styles.wrapper)}>
      <div className={styles.card}>
        <AniImg className={styles.imgContainer}>
          <img src={img} data-type={'scale'} alt={'customer-image'} className={styles.img} loading={'lazy'}/>
        </AniImg>
        <div className={styles.content} title={desc}>
          <CusIcon type="xtyinhao" className={styles.iconfont} />
          <span className={`xt-text ${styles.desc}`}>{desc}</span>
          <span className={styles.author}>{author}</span>
        </div>
      </div>
    </div>
  )
}

export default CustomerCard
