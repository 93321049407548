import React from 'react'
import { useTranslation } from 'hooks'
import styles from './index.module.scss'
import { BannerProps } from 'types/international'
import Title from 'components/Title'
import CustomCarousel from 'components/CustomCarousel'
import MediaCard from 'components/MediaCard'
import { useUserAgentState } from 'context';

const Media = () => {
  const { t } = useTranslation()
  const { isMobile } = useUserAgentState()
  const ul = [
    t(`home-media-ul-1`),
    t(`home-media-ul-2`),
    t(`home-media-ul-3`),
  ] as unknown as {title: string; desc: string; url: string}[]

  return (
    <section className={styles.section}>
      <Title>{t(`home-media-title`)}</Title>
      {isMobile ?
        <div className={styles.mobileContent}>
          {ul.map((info, index) => (
            <MediaCard
              key={index} info={{ ...info, img: t(`home-media-img-media${index + 1}`) }}
              button={t(`home-media-button`)}
              onClick={() => (window.open(info?.url, '_blank'))} />
          ))}
        </div>
        :
        <div className={styles.content}>
          <CustomCarousel arrows length={ul?.length ?? 0}>
            {ul.map((info, index) => (
              <MediaCard
                key={index} info={{ ...info, img: t(`home-media-img-media${index + 1}`) }}
                button={t(`home-media-button`)} />
            ))}
          </CustomCarousel>
        </div>
      }
    </section>
  )
}

export default Media
