import styles from './index.module.scss'
import AniImg from 'components/AniImg'
import classNames from 'classnames';
import Button from 'components/CusButton'

interface OwnProps {
  info: {title: string; desc: string; url: string; img: string}
  button: string
  onClick?: () => void
}

const MediaCard = ({ info, button, onClick }: OwnProps) => {
  const { title, desc, img, url } = info

  return (
    <div className={classNames('xt-row', styles.wrapper)} onClick={onClick}>
      <div className={styles.left}>
        <div className={styles.inner}>
          <h3 className={classNames('xt-h3', styles.title)}>{title}</h3>
          <span className={classNames('xt-text', styles.desc)}>{desc}</span>
          <Button onClick={() => (window.open(url, '_blank'))} className={styles.btn} text={button} />
        </div>
      </div>
      <div className={styles.right}>
        <AniImg className={styles.inner}>
          <img
            src={img} alt={'media-image'} loading={'lazy'}
            className={styles.img} data-type={'scale'}
          />
        </AniImg>
      </div>
    </div>
  )
}

export default MediaCard
