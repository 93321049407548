import React from "react";
import { GetStaticProps, GetServerSideProps } from "next";
import { useRouter } from "next/router";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import NewLayout from "layout-new";
import Layout, { getNoLayout } from "layout";
import OldHome from "components/OldHome";
import NewHome from "components/Home";
import { useCommonState } from "context";
import { NextPageWithLayout } from "types/reactExtra";
import { useMessage } from "hooks";
import { LOCATIONS } from "constants/regions";

export const getServerSideProps: GetServerSideProps = async ({ locale, res, params }) => {
  res.setHeader("Cache-Control", "public, s-maxage=10, stale-while-revalidate=599");

  return {
    props: {
      ...(await serverSideTranslations(locale || "zh-HK")),
      params,
    },
  };
};

const Home: NextPageWithLayout<PageProps & { params: { site: string } }> = (props) => {
  const { isHK, isHKLandingConsult, isHKLanding } = useCommonState();
  const router = useRouter();
  useMessage({ router });

  if (isHK || isHKLanding || isHKLandingConsult) {
    return (
      <Layout>
        <OldHome {...props} />
      </Layout>
    );
  }
  return (
    <NewLayout>
      <NewHome {...props} />
    </NewLayout>
  );
};

Home.getLayout = getNoLayout;

export default Home;
